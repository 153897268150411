@import "../../../global.scss";

.Store {
    position: relative;

    .entire {
        background: rgb(253,248,244);
        background: linear-gradient(90deg, rgba(253,248,244,1) 0%, rgba(251,235,220,1) 10%);

        .hero {
            background-image: url('/public/assets/img/patnerBreadcrum.png');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            height: 100vh;
            width: 100%;
            padding: 2.5rem;

            .heroText {
                width: 30rem;
                float: right;
                text-align: left;

                h2 {
                    font-weight: 700;
                    font-size: 2.5rem;
                    color: $darkVariaty;
                }

                h4 {
                    font-weight: 600;
                    font-size: 1rem;
                    margin: .5rem 0;
                }


                p {
                    font-size: .8rem;
                }

                .button {
                    margin: 5rem 0;
                    display: flex;
                    justify-content: center;

                    .btn {
                        padding: .8rem 2rem;
                        font-weight: 600;
                        margin: 0 1rem;
                        color: black;
                        box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
                        border-radius: .2rem;
                    }

                    .getStarted {
                        background-color: $mainColor;
                        border: 1px solid $mainColor;
                    }
                }
            }

            @media (max-width: 480px) {
                .heroText {
                    width: auto;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                }
            }

            @media (max-width: 760px) {
                .heroText {
                    width: fit-content;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        @media (max-width: 760px) {
            .hero {
                height: 100vh;
                background-size: fill;
                display: flex;
                align-items: center;
            }
        }
    }

    .how_work {
        padding: 2rem 0;
        color: #4D4D4D;
        background-image: url('/public/assets/img/pt_bg.png');

        h2 {
            margin: 1rem auto;
            font-weight: 700;
            font-size: 2rem;
        }
        .cardList {
            display: flex;
            justify-content: center;
            gap: 1rem;

            .cards {
                width: 24%;
//                 height: 15rem;
                border: 1px solid transparent;
                background: #fff;
                box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
                border-radius: 1rem;
                margin: 1rem 0;
                padding: 1.5rem;

                h4 {
                    font-weight: 650;
                    font-size: 1.2rem;
                    text-wrap: none;
                }

                p {
                    line-height: 120%;
                    text-align: left;
                    font-size: .8rem;
                    line-height: 150%;
                }
            }
        }

        @media (max-width: 480px) {
            .cardList {
                flex-direction: column;

                .cards {
                    margin: .5rem auto;
                }
            }
        }

        @media (max-width: 760px) {
            .cardList {
                flex-direction: column;

                .cards {
                    margin: .5rem auto;
                    width: 18rem;
                }
            }
        }

        .Experience {
            display: flex;
            padding: 2rem 0;

            .text {
                text-align: left;

                h3{
                    font-weight: 700;
                    font-size: 1.5rem;
                }

                p{
                    font-size: 1rem;
                }
            }
        }
        @media (max-width: 480px) {
            .Experience{
                flex-direction: column;
                padding: 2rem;

                .hide {
                    display: none;
                }
            }
        }

        @media (max-width: 760px) {
            .Experience{
                flex-direction: column;
                padding: 2rem;

                .hide {
                    display: none;
                }
            }
        }
    }
}
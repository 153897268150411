@import "../global.scss";



.Terms {
    padding: 2rem 0;
    display: relative;
    text-align: left;

    h1 {
        font-weight: 700;
        color: $darkVariaty;
    }

    .Text {
        padding: 2rem 0;

        h2 {
           font-weight: 600;
           color: #706F6C;
        }
        p{
        font-weight: 400;
            color: #706F6C;
           
        }

        ol {
            padding-left: 1.2rem;

            li {
                font-weight: 700;
                font-size: 1rem;
                padding: .3rem 0;
            }

            ol {
                padding: .5rem 1rem;

                li {
                    font-weight: 500;
                    font-size: .8rem;
                    padding: .1rem 0;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .Terms {
        padding: 3rem 2rem;
    }
}
@import "../../../global.scss";

.packages {
    border-radius: 1rem;
    background-color: $mainColor;
    padding: 3rem;
    margin: 2rem auto;

    .packageText {
        margin-bottom: 2.5rem;
        h2 {
            font-weight: 700;
            font-size: 2.5rem;
        }
    }

    @media (max-width: 480px) {
        .packageText {
            margin-bottom: 2.5rem;
            h2 {
                font-size: 2rem;
            }
        }
    }

    .packageList {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .packageItem {
            height: 24rem;
            width: 30%;
            background-color: white;
            border-radius: .6rem;
            box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .head {
                display: flex;
                justify-content: center;
                align-items: center;

                * {
                    margin: 0 .3rem;
                }

                h3 {
                    font-weight: 700;
                    font-size: 1.8rem;
                    text-wrap: nowrap;
                }
            }

            .subhead {

                h4 {
                    font-weight: 680;
                    font-size: 1.2rem;
                }
            }

            .text {

                * {
                    line-height: 120%;
                }
            }

            .button {
                a {
                    padding: .5rem 1.5rem;
                    text-decoration: none;
                    color: #000;
                    background-color: $mainColor;
                    box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
                    border: 1px solid $mainColor;
                    border-radius: .5rem;
                    font-weight: 700;
                }
            }
        }
        @media (max-width: 480px) {
            .packageItem {
                margin: .5rem 0;
            }
        }

        @media (max-width: 760px) {
            .packageItem {
                margin: .5rem 0;
                width: 22rem;
            }
        }
    }

    @media (max-width: 480px) {
        .packageList {
            flex-direction: column;
            justify-content: center;
        }
    }

    @media (max-width: 760px) {
        .packageList {
            flex-direction: column;
            justify-content: center;
        }
    }
}

@media (max-width: 480px) {
    .packages {
        padding: 1rem;
        margin: 2rem auto;
    }
}

@media (max-width: 760px) {
    .packages {
        padding: 1rem;
        margin: 2rem auto;
    }
}
@font-face {
    font-family: 'Gilroy';
    src: local("Gilroy"), url('../fonts/Gilroy-ExtraBold.otf') format('truetype'),
        url('../fonts/Gilroy-Light.otf') format('truetype'),
        url('../fonts/Gilroy-SemiBold.ttf') format('truetype'),
        url('../fonts/Gilroy-Regular.ttf') format('truetype'),
        url('../fonts/Gilroy-Medium.ttf') format('truetype');
    /* Add additional font formats if necessary */
    /* Specify the correct path to the font files */
}

$secondayColor : #FFEABB;
$mainColor : #F3B734;
$darkVariaty : #A27A23;
$mobileScreen : "(max-width: 991px)";
ol {
    counter-reset: item
}

li {
    display: block
}

li:before {
    content: counters(item, ".") " ";
    counter-increment: item
}
@import "../../../global.scss";

.whyUs {

    margin: 2rem auto;
    padding: 3rem 0;

    .text {

        h2 {
            font-weight: 700;
            font-size: 2.5rem;
        }

        h3 {
            font-weight: 600;
            font-size: 2rem;
            color: #444444;
        }
    }

    @media (max-width: 480px) {
        .text {

            h2 {
                font-size: 2rem;
            }
    
            h3 {
                font-size: 1.2rem;
            }
        }
    }

    @media (max-width: 760px) {
        .text {

            h2 {
                font-size: 2.2rem;
            }
    
            h3 {
                font-size: 1.4rem;
            }
        }
    }

    .cardList {
        display: flex;
        justify-content: center;
        gap: 1rem;

        .cards {
            width: 24%;
            height: 15rem;
            border: 1px solid transparent;
            box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
            border-radius: 1rem;
            margin: 1rem auto;
            padding: 2rem;

            h4 {
                font-weight: 650;
                font-size: 1.2rem;
            }

            p {
                line-height: 120%;
            }
        }
    }

    @media (max-width: 480px) {
        .cardList {
            flex-direction: column;
    
            .cards {
                margin: .5rem auto;
            }
        }
    }

    @media (max-width: 760px) {
        .cardList {
            flex-direction: column;
    
            .cards {
                margin: .5rem auto;
                width: 18rem;
            }
        }
    }
}

@font-face {
  font-family: 'Gilroy';
  src: local("Gilroy"),url('../fonts/Gilroy-ExtraBold.otf') format('truetype'),
    url('../fonts/Gilroy-Light.otf') format('truetype'),
    url('../fonts/Gilroy-SemiBold.ttf') format('truetype'),
    url('../fonts/Gilroy-Regular.ttf') format('truetype'),
    url('../fonts/Gilroy-Medium.ttf') format('truetype');
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}



@import './Components/shared/header/Header.scss';
@import './Components/home/intro/Intro.scss';
@import './Components/about/About.scss';
@import './Components/partner/Partner.scss';
@import './Components/partner/Move/Move.scss';
@import './Components/partner/Pack/Pack.scss';
@import './Components/services/Services.scss';
@import './Components/home/our-services/Service.scss';
@import './Components/home/package/Packages.scss';
@import './Components/home/why-us/WhyUs.scss';
@import './Components/signup/SignUp.scss';
@import './Components/login/Login.scss';
@import './Components/shared/footer/Footer.scss';
@import './Components/services/local/local.scss';
@import './Components/services/office/office.scss';
@import './Components/services/store/store.scss';
@import './Components/services/interstate/interstate.scss';
@import './Components/quote/Quote.scss';
@import './Components/Faq.scss';
@import './Components/Terms.scss';
@import './Components/reuse/Style.scss';

.app {
  text-align: center;
  height: 100vh;

  .section 
  {
    width: 100%;
    position: relative;
    top: 80px;
    scrollbar-width: none;
    
    &::-webkit-scrollbar{
      display: none;
    }
  }
}
ol {
  counter-reset: item
}

li {
  display: block
}

li:before {
  content: counters(item, ".") " ";
  counter-increment: item
}
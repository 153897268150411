@import "../../../global.scss";

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header 
{
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    background-color: $secondayColor;
    
    > * {
        color: black;
        font-weight: 600;
    }

    .header-scrolled, .header-inner-pages 
    {
        background: #2A2E36;
    }

    .logo {
        font-size: 30px;
        margin: 0;
        padding: 0;
        line-height: 1;
        font-weight: 600;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-decoration: none;
        color: #000;

        img {
            max-height: 50px;
        }
    }

    /*--------------------------------------------------------------
    # Navigation Menu
    --------------------------------------------------------------*/
    /**
    * Desktop Navigation 
    */
    .navbar {
        padding: 0;

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            list-style: none;
            align-items: center;

            li {
                position: relative;
            }

            a, a:focus, .nav-link 
            {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0 10px 30px;
                font-size: 15px;
                font-weight: 600;
                white-space: nowrap;
                transition: 0.3s;
                text-transform: capitalize;
                cursor: pointer;

                i {
                    font-size: 12px;
                    line-height: 0;
                    margin-left: 5px;
                }

                &:hover {
                    color: $mainColor;
                }
            }
        }

        .getstarted, .getstarted:focus 
        {
            padding: 8px 20px;
            margin-left: 30px;
            border-radius: 5px;
            font-size: 14px;
            border: 2px solid $mainColor;
            background: $mainColor;
            font-weight: 700;
            text-decoration: none;
            color: #000;
            box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);

            &:hover
            {
                color: #000;
            }
        }

        .dropdown 
        {
            ul {
                display: block;
                position: absolute;
                left: 14px;
                top: calc(100% + 30px);
                margin: 0;
                padding: 10px 0;
                z-index: 99;
                opacity: 0;
                visibility: hidden;
                background: #fff;
                box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
                transition: 0.3s;
                border-radius: 4px;

                li {
                    min-width: 200px;
                }

                a {
                    padding: 10px 20px;
                    font-size: 14px;
                    text-transform: none;
                    font-weight: 500;
                    color: #0c3c53;
                    text-decoration: none;

                    i {
                        font-size: 12px;

                        &:hover > a {
                            color: $mainColor;
                        };
                    }

                    &:hover, .active:hover {
                        color: $mainColor;
                    }
                }
            }

            &:hover > ul {
                opacity: 1;
                top: 100%;
                visibility: visible;
            }

            .dropdown{
                ul {
                    top: 0;
                    left: calc(100% - 30px);
                    visibility: hidden;
                }

                &:hover > ul {
                    opacity: 1;
                    top: 0;
                    left: 100%;
                    visibility: visible;
                }
            }
        }

        @media (max-width: 1366px) {
            .dropdown{
                .dropdown{
                    ul {
                        left: -90%;
                    }

                    &:hover > ul {
                        left: -100%;
                    }
                }
            }
        }

        @media (max-width: 991px){
            ul {
                display: none;
            }
        }

        /**
        * Mobile Navigation 
        */

        .mobile-nav-toggle {
            font-size: 28px;
            cursor: pointer;
            display: none;
            line-height: 0;
            transition: 0.5s;

            .bi-x {

            }

            @media (max-width: 991px) {
                display: block;
            }
        }

    }

    .navbar-mobile {
        position: fixed;
        overflow: hidden;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: $secondayColor;
        transition: 0.3s;
        z-index: 999;

        .mobile-nav-toggle {
            position: absolute;
            top: 15px;
            right: 15px;
        }

        ul {
            display: block;
            position: absolute;
            top: 55px;
            right: 15px;
            bottom: 15px;
            left: 15px;
            padding: 10px 0;
            border-radius: 10px;
            background-color: #fff;
            color: #2A2E36;
            overflow-y: auto;
            transition: 0.3s;

            li {
                padding: 0 1.2rem 0 0;
            }

            .dropdown {

                ul {
                    position: static;
                    display: none;
                    margin: 10px 20px;
                    padding: 10px 0;
                    z-index: 99;
                    opacity: 1;
                    visibility: visible;
                    background: #fff;
                    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    
                    li {
                        min-width: 200px;
                    }
    
                    a {
                        padding: .5rem 1rem;
    
                        i {
                            font-size: 12px;
                        }
                    }
                }
    
                .dropdown-active {
                    display: block;
                    visibility: visible !important;
                }
            }
        }

        .getstarted, .getstarted:focus {
            margin: 15px;
            color: #2A2E36;
        }
    }
}
@import "../../global.scss";

.Services {
    margin: 2rem auto;
    padding: 2rem 0;
    position: relative;
    background-image: url('/public/assets/img/home/serviceBreadcrum.png');

    h1 {
        color: $darkVariaty;
        font-weight: 700;
        font-size: 2.5rem;
    }

    > p {
        font-weight: 600;
        padding: .5rem 1.5rem;
    }

    .serList {
        margin: 2rem auto;
        
        .row {
            margin: 2rem auto;
        }

        a {
            text-decoration: none;
            color: black;
            font-weight: 700;
        }
    }
}
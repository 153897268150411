@import "../../global.scss";

.Partner{
    position: relative;

    .partnerText{
        margin: 2rem auto;

        h1 {
            font-weight: 700;
            color: $darkVariaty;
            margin: 1rem 0;
        }

        p {
            margin: 0 15%;
        }
    }

    .serList {
        margin: 2rem auto;
        padding: 2rem 0;

        .row {
            margin: 2rem auto;

            .imgCont {
                width: 18rem;
                display: flex;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .button{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .btn {
                    padding: .8rem 2rem;
                    font-weight: 600;
                    margin: 0 1rem;
                    color: black;
                    box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
                    background-color: $mainColor;
                    border: .5px solid $mainColor;
                }
            }

            .col {
                display: flex;
                justify-content: center;
            }
        }
    }
}
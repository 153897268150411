@import "../../../global.scss";

.Pack {
    text-align: left;

    .column {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 3rem 0;

        h1 {
            font-weight: 700;
            color: $darkVariaty;
        }

         p {
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 120%;
        }

        @media (max-width: 760px) {
            p, h1 {
                text-align: center;
            }
        }

        .imgCont {
            display: flex;
            justify-content: center;

            img {
                width: 25rem;
            }
        }

        form {
            margin: 0 3rem;

            span {
                color: red;
            }

            h5 {
                font-weight: 600;
            }

            .form-group{
                .form-label{
                    font-weight: 600;
                }

                input {
                    border: 1px solid #000;
                    border-radius: .2rem;
                    padding: .7rem 1rem;
                    outline: none;
                }

                .btn {
                    background: $mainColor;
                    padding: .3rem 2.5rem;
                    font-weight: 600;
                }
            }
        }
    }

    @media (max-width: 760px) {
        .column{
            margin: 3rem 1rem;
        }
    }
}
@import "../../../global.scss";

.serSec {
    height: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;


    .first {
        background-color: $mainColor;
        height: 80%;
        width: 90%;
        position: absolute;
        top: 3rem;
        right: 2.5rem;
        border-radius: 0 0 1rem 0;
    }

    .second {
        background-color: white;
        height: 80%;
        width: 90%;
        position: absolute;
        top: 1.5rem;
        border-radius: 0 0 1rem 0;

        .serSecText {
            margin-bottom: 3rem;
            
            h2 {
                font-weight: 700;
                font-size: 2.5rem;
            }

            p {
                font-weight: 600;
                font-size: 1.2rem;
            }
        }

        @media (max-width: 480px) {
            .serSecText {
                margin-bottom: 1.5rem;

                h2 {
                    font-size: 2rem;
                }
    
                p {
                    font-size: .8rem;
                }
            }
        }

        .serColl {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem;

            .imgCont {
                width: 20rem;
                height: 20rem;
                h3 {
                    margin-bottom: 2rem;
                    font-weight: 700;
                    font-size: 1.5rem;
                }

                img {
                    // width: 100%;
                    // height: 90%;
                    object-fit: cover;
                }
            }

            @media (max-width: 480px) {
                .imgCont {
                    width: 10rem;
                    height: 10rem;
                    margin: 1rem 0;

                    h3 {
                        margin-bottom: 0;
                        font-weight: 700;
                        font-size: 1rem;
                    }

                    img {
                        width: 100%;
                        height: 90%;
                        object-fit: contain;
                    }
    
                }
            }

            @media (max-width: 760px) {
                .imgCont {
                    width: 14rem;
                    height: 14rem;
                    margin: 1rem 0;

                    h3 {
                        margin-bottom: 1rem;
                        font-weight: 700;
                        font-size: 1rem;
                    }

                    img {
                        width: 100%;
                        height: 90%;
                        object-fit: contain;
                    }
    
                }
            }
        }

        @media (max-width: 480px) {
            .serColl {
                flex-direction: column;
            }
        }

        @media (max-width: 760px) {
            .serColl {
                flex-direction: column;
            }
        }
    }
}

@media (max-width: 480px) {
    .serSec {
        height: 60rem;
    }
}

@media (max-width: 760px) {
    .serSec {
        height: 75rem;
    }
}
@import "../../global.scss";

.login {
    width: 40%;
    height: fit-content;
    margin: 0 auto;
    text-align: left;
    padding: 2rem 0;

    @media (max-width: 991px) {
        width: 100%;
        padding: 1rem 2.5rem;
    }

    h1 {
        font-weight: 700;
        font-size: 2.5rem;
    }

    p {
        font-weight: 600;
        font-size: .8rem;
        a {
            color: $mainColor;
            text-decoration: none;
        }
    }

    .form {

        & .form-group {
            margin: 1.3rem 0;

            & .form-label {
                font-weight: 600;
                font-size: 1rem;
            }

            & .form-link {
                font-weight: 600;
                font-size: 1rem;
            }
            
            .form-control {
                padding: .6rem .7rem;
            }

            .small {
                font-size: .8rem;
            }

            a {
                text-decoration: none;
            }

            button {
                background-color: $mainColor;
                border: 1px solid $mainColor;
                border-radius: .2rem;
                padding: .5rem 1rem;
                font-weight: 700;
                font-size: 1.2rem;
            }

            p {
                font-weight: 600;
                font-size: 1rem;
            }

            .plainLink {
                border: none;
                color: $mainColor;
                margin: 0;
                font-size: .8rem;
                font-weight: 700;
            }
        }

        .form-link {

            p {
                font-weight: 700;
                font-size: 1rem
            }
            a {
                font-size: 1.5rem;
                padding: .05rem .3rem;
                margin: 0 .5rem 0 0;
                text-decoration: none;
                color: black;
                border: 1px solid black;
                border-radius: 50%;
            }
        }
    }
}
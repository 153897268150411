@import "../../global.scss";

.About {
    margin: 2rem auto;
    padding: 2rem 0;
    position: relative;

    h1 {
        color: $darkVariaty;
        font-weight: 700;
        font-size: 2.5rem;
    }

    > p {
        font-weight: 600;
        padding: .5rem 1.5rem;
    }

    .Aboutintro {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;

        .text {
            padding: 2rem;

            h2 {
                font-weight: 700;
                color: #4D4D4D;
            }

            div {
                margin: 2rem 0;

                p {
                    font-size: .9rem;
                }
            }
        }
    }

    .vision, .mission {
        // background-color: $secondayColor;
        border-radius: .5rem;
        padding: 1.5rem 6rem;
        margin: 2rem auto;
        width: 70%;
        // box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);

        h2 {
            font-weight: 700;
            margin-bottom: 1.5rem;
        }

        p {
            font-weight: 600;
        }
    }
}
.ol {
  counter-reset: item;
}

.li {
  display: block;
}

.li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

.timeline {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px 0;
  padding-left: 20px;
}

.timeline::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #ddd;
}

.timeline-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.timeline-item.active .timeline-icon {
  background: #a27a23;
}

.timeline-icon {
  width: 20px;
  height: 20px;
  background: #ddd;
  border-radius: 50%;
  position: absolute;
  left: -19px;
}

.timeline-content {
  margin-left: 20px;
  padding: 5px;
}

.status {
  font-weight: bold;
  color: #212120;
}

.date {
  font-size: 1rem;
  color: black;
}

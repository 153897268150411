@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Damion&display=swap");

@font-face {
  font-family: "Gilroy-SemiBold";
  src: local("Gilroy-SemiBold"),
    url("./fonts/Gilroy-SemiBold.ttf") format("truetype");
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}
@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url("./fonts/Gilroy-Regular.ttf") format("truetype");
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}
@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url("./fonts/Gilroy-Medium.ttf") format("truetype");
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
  background-color: #ffeabb;
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
}
#header > * {
  color: black;
  font-weight: 600;
  font-family: "Gilroy-Regular";
}
#header .header-scrolled,
#header .header-inner-pages {
  background: #2a2e36;
}
#header .logo {
  font-family: "Gilroy-SemiBold";
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
}
#header .logo img {
  max-height: 50px;
}
#header .navbar {
  padding: 0;
  /**
  * Mobile Navigation 
  */
}
#header .navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
#header .navbar ul li {
  position: relative;
  font-family: "Gilroy-Regular";
}
#header .navbar ul a,
#header .navbar ul a:focus,
#header .navbar ul .nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Gilroy-Regular";
  white-space: nowrap;
  transition: 0.3s;
  text-transform: capitalize;
  cursor: pointer;
}
#header .navbar ul a i,
#header .navbar ul a:focus i,
#header .navbar ul .nav-link i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
#header .navbar ul a:hover,
#header .navbar ul a:focus:hover,
#header .navbar ul .nav-link:hover {
  color: #f3b734;
}
#header .navbar .getstarted,
#header .navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 5px;
  font-size: 14px;
  border: 2px solid #f3b734;
  background: #f3b734;
  font-weight: 700;
  font-family: "Gilroy-Regular";
  text-decoration: none;
  color: #000;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
}
#header .navbar .getstarted:hover,
#header .navbar .getstarted:focus:hover {
  color: #000;
  background-color: #a27a23;
  border: 2px solid #a27a23;
}
#header .navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
  font-family: "Gilroy-Regular";
}
#header .navbar .dropdown ul li {
  min-width: 200px;
}
#header .navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  font-family: "Gilroy-Regular";
  color: #0c3c53;
  text-decoration: none;
}
#header .navbar .dropdown ul a i {
  font-size: 12px;
}
#header .navbar .dropdown ul li a {
  font-family: "Gilroy-Regular";
}
#header .navbar .dropdown ul a i:hover > a {
  color: #f3b734;
}
#header .navbar .dropdown ul a:hover,
#header .navbar .dropdown ul a .active:hover {
  color: #f3b734;
}
#header .navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
#header .navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
#header .navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  #header .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  #header .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}
@media (max-width: 991px) {
  #header .navbar ul {
    display: none;
  }
}
#header .navbar .mobile-nav-toggle {
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
@media (max-width: 991px) {
  #header .navbar .mobile-nav-toggle {
    display: block;
  }
}
#header .navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ffeabb;
  transition: 0.3s;
  z-index: 999;
}
#header .navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
#header .navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  color: #2a2e36;
  overflow-y: auto;
  transition: 0.3s;
}
#header .navbar-mobile ul li {
  padding: 0 1.2rem 0 0;
}
#header .navbar-mobile ul .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
#header .navbar-mobile ul .dropdown ul li {
  min-width: 200px;
}
#header .navbar-mobile ul .dropdown ul a {
  padding: 0.5rem 1rem;
}
#header .navbar-mobile ul .dropdown ul a i {
  font-size: 12px;
}
#header .navbar-mobile ul .dropdown .dropdown-active {
  display: block;
  visibility: visible !important;
}
#header .navbar-mobile .getstarted,
#header .navbar-mobile .getstarted:focus {
  margin: 15px;
  color: #2a2e36;
}

.intro {
  position: relative;
  background-image: url("/public/assets/img/home/hero.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  width: screen;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden; /* Ensures the pseudo-element doesn't overflow */
}

.intro::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Black with 60% opacity */
  z-index: 1; /* Ensure the overlay appears above the background image */
}

.intro > * {
  position: relative; /* Ensures content appears above the overlay */
  z-index: 2;
}

.intro .introText h2 {
  font-family: "Gilroy-Bold";
  font-size: 2.4rem;
  font-weight: 800;
}
.intro .introText div {
  width: 70%;
  margin: auto;
}
.intro .introText div p {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 120%;
  font-family: "Gilroy-SemiBold";
}
.intro .introText .buttons {
  margin: 3rem auto;
}
.intro .introText .buttons .btn {
  padding: 1rem 1rem;
  font-weight: 600;
  font-family: "Gilroy-semibold";
  margin: 0 0.5rem;
  color: black;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
}
.intro .introText .buttons .getQuote {
  background-color: #f3b734;
  border: 1px solid #f3b734;
  padding: 0rem 2.9rem;
}

.intro,
.introText .buttons .getQuote:hover {
  background-color: #a27a23;
}
.intro .introText .buttons .trackPackage {
  background-color: transparent;
  border: 1px solid #f5f5f5;
  color: #f5f5f5;
}
.intro .introText .buttons .trackPackage:hover {
  background-color: #a27a23;
}
@media (max-width: 991px) {
  .intro .introText .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;

    margin: 2rem auto;
  }
  .intro .introText .buttons .btn {
    margin: 0.5rem 0;
    display: block;
    width: fit-content;
    padding: 1rem 0.6rem;
  }
}
@media (max-width: 991px) {
  .intro .introText h2 {
    line-height: 100%;
  }
}
@media (max-width: 480px) {
  .intro .introText div {
    width: 80%;
  }
}
.intro .imageRow {
  display: flex;
  margin: 2rem auto;
}
.intro .imageRow .imgBox {
  margin: 0 1rem;
}
@media (max-width: 480px) {
  .intro .imgBox:not(:first-child) {
    display: none;
  }
}
@media (max-width: 760px) {
  .intro .imgBox:not(:nth-child(2n)) {
    display: none;
  }
}

.About {
  margin: 2rem auto;
  padding: 2rem 0;
  position: relative;
}
.About h1 {
  color: #a27a23;
  font-weight: 700;
  font-size: 2.5rem;
  font-family: "Gilroy-Medium";
}
.About > p {
  font-weight: 600;
  font-family: "Gilroy-SemiBold";
  padding: 0.5rem 1.5rem;
}
.About .Aboutintro {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.About .Aboutintro .text {
  padding: 2rem;
}
.About .Aboutintro .text h2 {
  font-weight: 700;
  font-family: "Gilroy-Medium";
  color: #4d4d4d;
}
.About .Aboutintro .text div {
  margin: 2rem 0;
}
.About .Aboutintro .text div p {
  font-size: 0.9rem;
}
.About .vision,
.About .mission {
  border-radius: 0.5rem;
  padding: 1.5rem 6rem;
  margin: 2rem auto;
  width: 70%;
}
.About .vision h2,
.About .mission h2 {
  font-weight: 700;
  margin-bottom: 1.5rem;
  font-family: "Gilroy-Medium";
}
.About .vision p,
.About .mission p {
  font-weight: 600;
  font-family: "Gilroy-SemiBold";
}

.Partner {
  position: relative;
}
.Partner .partnerText {
  margin: 2rem auto;
}
.Partner .partnerText h1 {
  font-weight: 700;
  color: #a27a23;
  margin: 1rem 0;
  font-family: "Gilroy-SemiBold";
}
.Partner .partnerText p {
  margin: 0 15%;
}
.Partner .serList {
  margin: 2rem auto;
  padding: 2rem 0;
}
.Partner .serList .row {
  margin: 2rem auto;
}
.Partner .serList .row .imgCont {
  width: 18rem;
  display: flex;
}
.Partner .serList .row .imgCont img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Partner .serList .row .button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Partner .serList .row .button .btn {
  padding: 0.8rem 2rem;
  font-weight: 600;
  margin: 0 1rem;
  font-family: "Gilroy-Medium";
  color: black;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  background-color: #f3b734;
  border: 0.5px solid #f3b734;
}
.Partner .serList .row .col {
  display: flex;
  justify-content: center;
}

.Move {
  text-align: left;
}
.Move .column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 3rem 0;
}
.Move .column h1 {
  font-weight: 700;
  color: #a27a23;
}
.Move .column .text {
  margin: 0 0 0 4rem;
}
.Move .column .text h3 {
  font-weight: 700;
}
.Move .column .text ul {
  margin: 0;
  padding: 1rem 0 1rem 1rem;
  list-style: square;
}
.Move .column .text ul li {
  margin: 0.3rem 0;
}
.Move .column .text ul li::marker {
  color: #f3b734;
}
.Move .column .text h6 {
  font-weight: 700;
  font-size: 1.2rem;
}
.Move .column .text p {
  font-size: 0.8rem;
}
.Move .column .text a {
  text-decoration: none;
  color: #f3b734;
}
@media (max-width: 760px) {
  .Move .column .text {
    margin: 0;
  }
  .Move .column .text p {
    text-align: left;
  }
}
.Move .column p {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 120%;
}
@media (max-width: 760px) {
  .Move .column p,
  .Move .column h1 {
    text-align: center;
  }
}
.Move .column .imgCont {
  display: flex;
  justify-content: center;
}
.Move .column .imgCont img {
  width: 25rem;
}
.Move .column form {
  margin: 0 3rem;
}
.Move .column form span {
  color: red;
}
.Move .column form h5 {
  font-weight: 600;
}
.Move .column form .form-group .form-label {
  font-weight: 600;
}
.Move .column form .form-group input {
  border: 1px solid #000;
  border-radius: 0.2rem;
  padding: 0.7rem 1rem;
  outline: none;
}
.Move .column form .form-group .btn {
  background: #f3b734;
  padding: 0.3rem 2.5rem;
  font-weight: 600;
}
@media (max-width: 760px) {
  .Move .column {
    margin: 3rem 1rem;
  }
}

.Pack {
  text-align: left;
}
.Pack .column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 3rem 0;
}
.Pack .column h1 {
  font-weight: 700;
  color: #a27a23;
}
.Pack .column p {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 120%;
}
@media (max-width: 760px) {
  .Pack .column p,
  .Pack .column h1 {
    text-align: center;
  }
}
.Pack .column .imgCont {
  display: flex;
  justify-content: center;
}
.Pack .column .imgCont img {
  width: 25rem;
}
.Pack .column form {
  margin: 0 3rem;
}
.Pack .column form span {
  color: red;
}
.Pack .column form h5 {
  font-weight: 600;
}
.Pack .column form .form-group .form-label {
  font-weight: 600;
}
.Pack .column form .form-group input {
  border: 1px solid #000;
  border-radius: 0.2rem;
  padding: 0.7rem 1rem;
  outline: none;
}
.Pack .column form .form-group .btn {
  background: #f3b734;
  padding: 0.3rem 2.5rem;
  font-weight: 600;
}
@media (max-width: 760px) {
  .Pack .column {
    margin: 3rem 1rem;
  }
}

.Services {
  margin: 2rem auto;
  padding: 2rem 0;
  position: relative;
  background-image: url("/public/assets/img/home/serviceBreadcrum.png");
}
.Services h1 {
  color: #a27a23;
  font-weight: 700;
  font-size: 2.5rem;
}
.Services > p {
  font-weight: 600;
  padding: 0.5rem 1.5rem;
}
.Services .serList {
  margin: 2rem auto;
}
.Services .serList .row {
  margin: 2rem auto;
}
.Services .serList a {
  text-decoration: none;
  color: black;
  font-weight: 700;
}

.serSec {
  height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.serSec .first {
  background-color: #f3b734;
  height: 80%;
  width: 90%;
  position: absolute;
  top: 3rem;
  right: 2.5rem;
  border-radius: 0 0 1rem 0;
}
.serSec .second {
  background-color: white;
  height: 80%;
  width: 90%;
  position: absolute;
  top: 1.5rem;
  border-radius: 0 0 1rem 0;
}
.serSec .second .serSecText {
  margin-bottom: 3rem;
}
.serSec .second .serSecText h2 {
  font-weight: 700;
  font-size: 2.5rem;
}
.serSec .second .serSecText p {
  font-weight: 600;
  font-size: 1.2rem;
}
@media (max-width: 480px) {
  .serSec .second .serSecText {
    margin-bottom: 1.5rem;
  }
  .serSec .second .serSecText h2 {
    font-size: 2rem;
  }
  .serSec .second .serSecText p {
    font-size: 0.8rem;
  }
}
.serSec .second .serColl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.serSec .second .serColl .imgCont {
  width: 20rem;
  height: 20rem;
}
.serSec .second .serColl .imgCont h3 {
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 1.5rem;
}
.serSec .second .serColl .imgCont img {
  object-fit: cover;
}
@media (max-width: 480px) {
  .serSec .second .serColl .imgCont {
    width: 10rem;
    height: 10rem;
    margin: 1rem 0;
  }
  .serSec .second .serColl .imgCont h3 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 1rem;
  }
  .serSec .second .serColl .imgCont img {
    width: 100%;
    height: 90%;
    object-fit: contain;
  }
}
@media (max-width: 760px) {
  .serSec .second .serColl .imgCont {
    width: 14rem;
    height: 14rem;
    margin: 1rem 0;
  }
  .serSec .second .serColl .imgCont h3 {
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1rem;
  }
  .serSec .second .serColl .imgCont img {
    width: 100%;
    height: 90%;
    object-fit: contain;
  }
}
@media (max-width: 480px) {
  .serSec .second .serColl {
    flex-direction: column;
  }
}
@media (max-width: 760px) {
  .serSec .second .serColl {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .serSec {
    height: 60rem;
  }
}
@media (max-width: 760px) {
  .serSec {
    height: 75rem;
  }
}
.packages {
  border-radius: 1rem;
  background-color: #f3b734;
  padding: 3rem;
  margin: 2rem auto;
}
.packages .packageText {
  margin-bottom: 2.5rem;
}
.packages .packageText h2 {
  font-weight: 700;
  font-size: 2.5rem;
  font-family: "Gilroy-Medium";
}
@media (max-width: 480px) {
  .packages .packageText {
    margin-bottom: 2.5rem;
  }
  .packages .packageText h2 {
    font-size: 2rem;
  }
}
.packages .packageList {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.packages .packageList .packageItem {
  height: 24rem;
  width: 30%;
  background-color: white;
  border-radius: 0.6rem;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.packages .packageList .packageItem .head {
  display: flex;
  justify-content: center;
  align-items: center;
}
.packages .packageList .packageItem .head * {
  margin: 0 0.3rem;
}
.packages .packageList .packageItem .head h3 {
  font-weight: 700;
  font-family: "Gilroy-Medium";
  font-size: 1.8rem;
  text-wrap: nowrap;
}
.packages .packageList .packageItem .subhead h4 {
  font-weight: 680;
  font-family: "Gilroy-Medium";
  font-size: 1.2rem;
}
.packages .packageList .packageItem .text * {
  line-height: 120%;
  font-family: "Gilroy-SemiBold";
}
.packages .packageList .packageItem .button a {
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  color: #000;
  background-color: #f3b734;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border: 1px solid #f3b734;
  border-radius: 0.5rem;
  font-weight: 700;
}
@media (max-width: 480px) {
  .packages .packageList .packageItem {
    margin: 0.5rem 0;
  }
}
@media (max-width: 760px) {
  .packages .packageList .packageItem {
    margin: 0.5rem 0;
    width: 22rem;
  }
}
@media (max-width: 480px) {
  .packages .packageList {
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: 760px) {
  .packages .packageList {
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .packages {
    padding: 1rem;
    margin: 2rem auto;
  }
}
@media (max-width: 760px) {
  .packages {
    padding: 1rem;
    margin: 2rem auto;
  }
}
.whyUs {
  margin: auto;
  padding: 3rem 0;
}
.whyUs .text h2 {
  font-weight: 700;
  font-size: 2.5rem;
  font-family: "Gilroy-SemiBold";
}
.whyUs .text h3 {
  font-weight: 600;
  font-size: 2rem;
  color: #444444;
  font-family: "Gilroy-Medium";
}
@media (max-width: 480px) {
  .whyUs .text h2 {
    font-size: 2rem;
  }
  .whyUs .text h3 {
    font-size: 1.2rem;
  }
}
@media (max-width: 760px) {
  .whyUs .text h2 {
    font-size: 2.2rem;
  }
  .whyUs .text h3 {
    font-size: 1.4rem;
  }
}
.whyUs .cardList {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.whyUs .cardList .cards {
  width: 24%;
  height: 15rem;
  border: 1px solid transparent;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border-radius: 1rem;
  margin: 1rem auto;
  padding: 2rem;
}
.whyUs .cardList .cards h4 {
  font-weight: 650;
  font-size: 1.2rem;
  font-family: "Gilroy-Medium";
}
.whyUs .cardList .cards p {
  line-height: 120%;
  font-family: "Gilroy-Regular";
}
@media (max-width: 480px) {
  .whyUs .cardList {
    flex-direction: column;
  }
  .whyUs .cardList .cards {
    margin: 0.5rem auto;
  }
}
@media (max-width: 900px) {
  .whyUs .cardList {
    flex-direction: column;
  }
  .whyUs .cardList .cards {
    margin: 0.5rem auto;
    width: 18rem;
  }
}

.signup {
  width: 40%;
  height: fit-content;
  margin: 0 auto;
  text-align: left;
  padding: 2rem 0;
}
@media (max-width: 991px) {
  .signup {
    width: 100%;
    padding: 1rem 2.5rem;
  }
}
.signup h1 {
  font-weight: 700;
  font-size: 2.5rem;
}
.signup p {
  font-weight: 600;
  font-size: 0.8rem;
}
.signup p a {
  color: #f3b734;

  text-decoration: none;
}
.signup p a:hover {
  color: #a27a23;
}
.signup .form .form-group {
  margin: 1.3rem 0;
}
.signup .form .form-group .form-label {
  font-weight: 600;
  font-size: 1rem;
}
.signup .form .form-group .form-control {
  padding: 0.6rem 0.7rem;
}
.signup .form .form-group button {
  background-color: #f3b734;
  border: 1px solid #f3b734;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  font-weight: 700;
  font-size: 1.2rem;
}
.signup .form .form-group button:hover {
  background-color: #a27a23;
}
.signup .form .form-group p {
  font-weight: 600;
  font-size: 1rem;
}
.signup .form .form-link p {
  font-weight: 700;
  font-size: 1rem;
}
.signup .form .form-link a {
  font-size: 1.5rem;
  padding: 0.05rem 0.3rem;
  margin: 0 0.5rem 0 0;
  text-decoration: none;
  color: black;
  border: 1px solid black;
  border-radius: 50%;
}

.login {
  width: 40%;
  height: fit-content;
  margin: 0 auto;
  text-align: left;
  padding: 2rem 0;
}
@media (max-width: 991px) {
  .login {
    width: 100%;
    padding: 1rem 2.5rem;
  }
}
.login h1 {
  font-weight: 700;
  font-size: 2.5rem;
}
.login p {
  font-weight: 600;
  font-size: 0.8rem;
}
.login p a {
  color: #f3b734;
  text-decoration: none;
}
.login .form .form-group {
  margin: 1.3rem 0;
}
.login .form .form-group .form-label {
  font-weight: 600;
  font-size: 1rem;
}
.login .form .form-group .form-link {
  font-weight: 600;
  font-size: 1rem;
}
.login .form .form-group .form-control {
  padding: 0.6rem 0.7rem;
}
.login .form .form-group .small {
  font-size: 0.8rem;
}
.login .form .form-group a {
  text-decoration: none;
}
.login .form .form-group button {
  background-color: #f3b734;

  border: 1px solid #f3b734;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  font-weight: 700;
  font-size: 1.2rem;
}
.login .form .form-group button:hover {
  background-color: #a27a23;
}
.login .form .form-group p {
  font-weight: 600;
  font-size: 1rem;
}
.login .form .form-group .plainLink {
  border: none;
  color: #f3b734;
  margin: 0;
  font-size: 0.8rem;
  font-weight: 700;
}
.login .form .form-link p {
  font-weight: 700;
  font-size: 1rem;
}
.login .form .form-link a {
  font-size: 1.5rem;
  padding: 0.05rem 0.3rem;
  margin: 0 0.5rem 0 0;
  text-decoration: none;
  color: black;
  border: 1px solid black;
  border-radius: 50%;
}

#footer {
  font-size: 14px;
  position: relative;
  margin: 2rem auto;
  padding: 3rem 0 0 0;
}
#footer .faq {
  background-color: #ffeabb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-radius: 1.5rem 1.5rem 0 0;
}
#footer .faq .text {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#footer .faq .text * {
  margin: 0;
}
#footer .faq .text .line {
  border: 2px solid #a27a23;
  height: 4rem;
  width: 0;
  margin-right: 2rem;
}
#footer .faq .button a {
  padding: 0.5rem 4rem;
  background-color: #f3b734;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border: 1px solid #f3b734;
  border-radius: 0.5rem;
  font-weight: 700;
  font-size: 1.2rem;
  text-decoration: none;
  color: #000;
}
@media (max-width: 480px) {
  #footer .faq {
    flex-direction: column;
    justify-content: end;
  }
  #footer .faq .text {
    margin: 1rem 0;
  }
  #footer .faq .text h3 {
    margin-bottom: 0.5rem;
  }
  #footer .faq .text .line {
    height: 7rem;
  }
}
@media (max-width: 760px) {
  #footer .faq {
    flex-direction: column;
    justify-content: end;
  }
  #footer .faq .text {
    margin: 1rem 0;
  }
  #footer .faq .text h3 {
    margin-bottom: 0.5rem;
  }
  #footer .faq .text .line {
    height: 5rem;
  }
}
#footer h3 {
  font-size: 2rem;
  font-weight: 700;
  font-family: "Gilroy-Medium";
}
#footer .line {
  border: 2px solid white;
  width: 90%;
  margin: 1.5rem 0;
}
#footer .footer-newsletter {
  padding: 50px 0;
  background: #f3f5fa;
  text-align: center;
  font-size: 15px;
  color: #444444;
}
#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: #2a2e36;
}
#footer .footer-top {
  background: #fff;
}
#footer .footer-top .container {
  background-color: #a27a23;
  border-radius: 0 0 1.5rem 1.5rem;
  color: #fff;
  padding: 5rem;
}
#footer .footer-top .container .footer-contact {
  margin-bottom: 30px;
  text-align: left;
  padding: 0 1rem;
}
#footer .footer-top .container .footer-contact .fLogo {
  margin: 0 0 2rem 0;
}
#footer .footer-top .container .footer-contact p {
  font-size: 1rem;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Gilroy-Regular", sans-serif;
}
#footer .footer-top .container h4 {
  font-size: 16px;
  font-weight: bold;
  font-family: "Gilroy-Medium";
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .container .footer-links {
  margin-bottom: 30px;
  text-align: left;
  padding: 0 1rem;
}
#footer .footer-top .container .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .container .footer-links ul i {
  padding-right: 2px;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .container .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .container .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .container .footer-links ul a {
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  font-size: 1.4rem;
  font-family: "Gilroy-Regular";
}
#footer .footer-top .container .footer-links ul a:hover {
  text-decoration: none;
  color: #2a2e36;
}
#footer .footer-top .container .footer-links form input {
  display: block;
  width: 90%;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  margin: 0 0 1.5rem 0;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  outline: none;
  background-color: transparent;
}
#footer .footer-top .container .footer-links form input[type="email"] {
  border: 1px solid white;
  color: #fff;
}
#footer
  .footer-top
  .container
  .footer-links
  form
  input[type="email"]::placeholder {
  color: #fff;
}
#footer .footer-top .container .footer-links form input[type="submit"] {
  background-color: #f3b734;
  font-weight: 600;
}

#footer .footer-top .container .footer-links form input[type="submit"]:hover {
  background-color: #a27a23;
}
@media (max-width: 768px) {
  #footer .footer-top .container {
    padding: 3rem;
  }
}
#footer .footer-top .sLinks {
  background-color: transparent;
  padding: 0;
}
#footer .footer-top .social-links {
  display: flex;
  justify-content: end;
}
#footer .footer-top .social-links a {
  font-size: 1.5rem;
  display: inline-block;
  line-height: 1;
  transition: 0.3s;
  margin: 1.5rem 0 1.5rem 1rem;
  color: #f3b734;
}
@media (max-width: 480px) {
  #footer .footer-top .social-links {
    padding: 0;
    justify-content: start;
  }
}
#footer .footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
  align-items: center;
}
#footer .logo {
  float: left;
}
#footer .logo img {
  with: 60px;
  height: 60px;
}
#footer .copyright {
  float: right;
  color: #fff;
}
#footer .copyright a {
  text-decoration: underline #f3b734;
  color: #000;
  font-size: 0.9rem;
  font-weight: 700;
  margin-left: 1rem;
}
#footer .credits {
  float: right;
  font-size: 13px;
}
#footer .credits a {
  transition: 0.3s;
}
@media (max-width: 768px) {
  #footer .copyright,
  #footer .credits {
    text-align: center;
    float: none;
  }
  #footer .credits {
    padding-top: 4px;
  }
}

.Local {
  margin: 2rem auto;
  padding: 2rem 0;
}
.Local .Localintro {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.Local .Localintro .text h1 {
  color: #a27a23;
  font-weight: 700;
}
.Local .Localintro .text h5 {
  font-weight: 600;
}
.Local .Localintro .text div {
  margin: 2rem 0;
}
.Local .Localintro .text .button {
  margin: 2rem 0;
}
.Local .Localintro .text .button button {
  padding: 0.5rem 1.5rem;
  background-color: #f3b734;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border: 1px solid #f3b734;
  border-radius: 0.3rem;
  font-weight: 700;
}
.Local .value {
  border-radius: 0.5rem;
  padding: 1.5rem 6rem;
  margin: 2rem auto;
}
.Local .value h2 {
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.Local .value p {
  font-weight: 600;
}

.Office {
  margin: 2rem auto;
  padding: 2rem 0;
}
.Office .Officeintro {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.Office .Officeintro .text h1 {
  color: #a27a23;
  font-weight: 700;
}
.Office .Officeintro .text h5 {
  font-weight: 600;
}
.Office .Officeintro .text div {
  margin: 2rem 0;
}
.Office .Officeintro .text .button {
  margin: 2rem 0;
}
.Office .Officeintro .text .button button {
  padding: 0.5rem 1.5rem;
  background-color: #f3b734;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border: 1px solid #f3b734;
  border-radius: 0.3rem;
  font-weight: 700;
}
.Office .value {
  border-radius: 0.5rem;
  padding: 1.5rem 6rem;
  margin: 2rem auto;
}
.Office .value h2 {
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.Office .value p {
  font-weight: 600;
}

.Store {
  position: relative;
}
.Store .entire {
  background: rgb(253, 248, 244);
  background: linear-gradient(
    90deg,
    rgb(253, 248, 244) 0%,
    rgb(251, 235, 220) 10%
  );
}
.Store .entire .hero {
  background-image: url("/public/assets/img/patnerBreadcrum.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 100vh;
  width: 100%;
  padding: 2.5rem;
}
.Store .entire .hero .heroText {
  width: 30rem;
  float: right;
  text-align: left;
}
.Store .entire .hero .heroText h2 {
  font-weight: 700;
  font-size: 2.5rem;
  color: #a27a23;
}
.Store .entire .hero .heroText h4 {
  font-weight: 600;
  font-size: 1rem;
  margin: 0.5rem 0;
}
.Store .entire .hero .heroText p {
  font-size: 0.8rem;
}
.Store .entire .hero .heroText .button {
  margin: 5rem 0;
  display: flex;
  justify-content: center;
}
.Store .entire .hero .heroText .button .btn {
  padding: 0.8rem 2rem;
  font-weight: 600;
  margin: 0 1rem;
  color: black;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border-radius: 0.2rem;
}
.Store .entire .hero .heroText .button .getStarted {
  background-color: #f3b734;
  border: 1px solid #f3b734;
}
@media (max-width: 480px) {
  .Store .entire .hero .heroText {
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 760px) {
  .Store .entire .hero .heroText {
    width: fit-content;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 760px) {
  .Store .entire .hero {
    height: 100vh;
    background-size: fill;
    display: flex;
    align-items: center;
  }
}
.Store .how_work {
  padding: 2rem 0;
  color: #4d4d4d;
  background-image: url("/public/assets/img/pt_bg.png");
}
.Store .how_work h2 {
  margin: 1rem auto;
  font-weight: 700;
  font-size: 2rem;
}
.Store .how_work .cardList {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.Store .how_work .cardList .cards {
  width: 24%;
  border: 1px solid transparent;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border-radius: 1rem;
  margin: 1rem 0;
  padding: 1.5rem;
}
.Store .how_work .cardList .cards h4 {
  font-weight: 650;
  font-size: 1.2rem;
  text-wrap: none;
}
.Store .how_work .cardList .cards p {
  line-height: 120%;
  text-align: left;
  font-size: 0.8rem;
  line-height: 150%;
}
@media (max-width: 480px) {
  .Store .how_work .cardList {
    flex-direction: column;
  }
  .Store .how_work .cardList .cards {
    margin: 0.5rem auto;
  }
}
@media (max-width: 760px) {
  .Store .how_work .cardList {
    flex-direction: column;
  }
  .Store .how_work .cardList .cards {
    margin: 0.5rem auto;
    width: 18rem;
  }
}
.Store .how_work .Experience {
  display: flex;
  padding: 2rem 0;
}
.Store .how_work .Experience .text {
  text-align: left;
}
.Store .how_work .Experience .text h3 {
  font-weight: 700;
  font-size: 1.5rem;
}
.Store .how_work .Experience .text p {
  font-size: 1rem;
}
@media (max-width: 480px) {
  .Store .how_work .Experience {
    flex-direction: column;
    padding: 2rem;
  }
  .Store .how_work .Experience .hide {
    display: none;
  }
}
@media (max-width: 760px) {
  .Store .how_work .Experience {
    flex-direction: column;
    padding: 2rem;
  }
  .Store .how_work .Experience .hide {
    display: none;
  }
}

.Interstate {
  margin: 2rem auto;
  padding: 2rem 0;
}
.Interstate .Interstateintro {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.Interstate .Interstateintro .text h1 {
  color: #a27a23;
  font-weight: 700;
}
.Interstate .Interstateintro .text h5 {
  font-weight: 600;
}
.Interstate .Interstateintro .text div {
  margin: 2rem 0;
}
.Interstate .Interstateintro .text .button {
  margin: 2rem 0;
}
.Interstate .Interstateintro .text .button button {
  padding: 0.5rem 1.5rem;
  background-color: #f3b734;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border: 1px solid #f3b734;
  border-radius: 0.3rem;
  font-weight: 700;
}
.Interstate .value {
  border-radius: 0.5rem;
  padding: 1.5rem 6rem;
  margin: 2rem auto;
}
.Interstate .value h2 {
  font-weight: 700;
  margin-bottom: 1.5rem;
}
.Interstate .value p {
  font-weight: 600;
}

.Quote {
  text-align: left;
  padding: 2rem 1rem;
}
.Quote .text {
  text-align: center;
  padding: 1rem 0;
  position: relative;
}
.Quote .text .line {
  width: 5rem;
  border: 1px solid #f3b734;
  position: absolute;
  top: 50%;
  left: 39%;
}
.Quote .text h1 {
  font-weight: 800;
}
.Quote .text p {
  font-size: 0.8rem;
  font-weight: 600;
}
.Quote span {
  color: red;
}
.Quote .second .progress-bar {
  background-color: #f3b734;
  width: 10rem;
}
.Quote .second .row {
  margin: 2rem 1.5rem;
  justify-content: center;
}
.Quote .second .row .formColl {
  padding: 0.5rem 1.5rem;
}
.Quote .second .row .formColl h6 {
  font-weight: 700;
}
.Quote .second .row .formColl .form-group {
  margin: 1rem 0;
}
.Quote .second .row .formColl .form-group .form-label {
  font-weight: 600;
}
.Quote
  .second
  .row
  .formColl
  .form-group
  input[type="date"]::-webkit-calendar-picker-indicator {
  color: #f3b734;
}
.Quote .second .progressrow {
  margin: 1rem 0;
}
.Quote .second .buttons {
  display: flex;
  /* justify-content: space-between; */
  margin: 0 2rem;
}
.Quote .second .buttons .btn-next {
  background-color: #f3b734;
  border: 1px solid #f3b734;
  padding: 0.5rem 3rem;
  color: #000;
  font-weight: 700;
}
.Quote .second .buttons .btn-back {
  color: #f3b734;
  background-color: transparent;
  border: none;
  font-weight: 700;
}

@media (max-width: 480px) {
  .Quote {
    height: 60rem;
  }
}
@media (max-width: 760px) {
  .Quote {
    height: 75rem;
  }
}
.faqs {
  padding: 3rem 0rem;
  display: relative;
}
.faqs h1 {
  font-size: 2.5rem;
  font-weight: 650;
  color: #a27a23;
}
.faqs p {
  font-weight: 650;
}
.faqs .faqTable {
  text-align: left;
  border: 1px solid #000;
  border-radius: 0.5rem;
  padding: 0;
  margin: 2rem auto;
  width: 70%;
}
.faqs .faqTable .question {
  padding: 1rem;
}
.faqs .faqTable .question:not(:last-child) {
  border-bottom: 0.5px solid #000;
}
.faqs .faqTable .question .questionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faqs .faqTable .question .questionTitle h6 {
  font-weight: 600;
}
.faqs .faqTable .question .questionTitle i {
  cursor: pointer;
  font-weight: 700;
  font-size: 1.2rem;
  margin-right: 1rem;
}
.faqs .faqTable .question .questionText {
  width: 70%;
}
.faqs .faqTable .question .questionText p {
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0;
}
.faqs .faqTable .question .icon-dash {
  display: none;
}
.faqs .faqTable content:not(:last-child) {
  border-bottom: 0.5px solid #000;
}
@media (max-width: 768px) {
  .faqs .faqTable {
    width: 100%;
  }
  .faqs .faqTable .question .questionText {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .faqs {
    padding: 3rem 1rem;
  }
}
.Terms {
  padding: 3rem 0;
  display: relative;
  text-align: left;
}
.Terms h1 {
  font-weight: 700;
  color: #a27a23;
}
.Terms .Text {
  padding: 2rem 0;
}
.Terms .Text h2 {
  font-weight: 600;
  color: #706f6c;
}
.Terms .Text ol {
  padding-left: 1.2rem;
}
.Terms .Text ol li {
  font-weight: 700;
  font-size: 1rem;
  padding: 0.3rem 0;
}
.Terms .Text ol ol {
  padding: 0.5rem 1rem;
}
.Terms .Text ol ol li {
  font-weight: 500;
  font-size: 0.8rem;
  padding: 0.1rem 0;
}

@media (max-width: 768px) {
  .Terms {
    padding: 3rem 0.4rem;
  }
}
.stickyBar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0.5rem 0.5rem;
  background-color: #f3b734;
  border-radius: 0.5rem;
  cursor: pointer;
  position: fixed;
  bottom: 5rem;
  right: 1rem;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  z-index: 999;
  text-decoration: none;
  color: #000;
}
.stickyBar i {
  color: green;
  padding: 0 0.5rem;
}
.stickyBar p {
  padding: 0;
  margin: 0;
  font-weight: 600;
}

.cancelBar {
  cursor: pointer;
  position: fixed;
  bottom: 8rem;
  right: 1rem;
  z-index: 999;
  font-size: 20px;
}
.cancelBar i {
  color: rgb(34, 188, 17);
  padding: 0 0.3rem;
}

.stCon {
  display: none;
}

.app {
  text-align: center;
  height: 100vh;
}
.app .section {
  width: 100%;
  position: relative;
  top: 80px;
  scrollbar-width: none;
}
.app .section::-webkit-scrollbar {
  display: none;
}

.why:hover {
  background-color: #f3b734;
  opacity: 0.85;
}

@import "../../global.scss";

.stickyBar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: .5rem 1rem;
    background-color: $mainColor;
    border-radius: .5rem;
    cursor: pointer;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
    z-index: 999;
    text-decoration: none;
    color: #000;

    i {
        color: green;
        padding: 0 .5rem;
    }

    p {
        padding: 0;
        margin: 0;
        font-weight: 600;
    }
}

.cancelBar {
    cursor: pointer;
    position: fixed;
    bottom: 3.65rem;
    right: 1rem;
    z-index: 999;

    i {
        color: green;
        padding: 0 .5rem;
    }
}

.stCon {
    display: none;
}
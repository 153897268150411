@import "../global.scss";

.faqs {
    padding: 3rem 0;
    display: relative;

    h1 {
        font-size: 2.5rem;
        font-weight: 650;
        color: $darkVariaty;
    }

    p {
        font-weight: 650;
    }

    .faqTable {
        text-align: left;
        border: 1px solid #000;
        border-radius: .5rem;
        padding: 0;
        margin: 2rem 0;
        width: 70%;

        .question{
            padding: 1rem;

            &:not(:last-child){
                border-bottom: .5px solid #000;
            }

            .questionTitle{
                display: flex;
                justify-content: space-between;
                align-items: center;

                h6 {
                    font-weight: 600;
                }

                i {
                    cursor: pointer;
                    font-weight: 700;
                    font-size: 1.2rem;
                    margin-right: 1rem;
                }
            }

            .questionText{
                width: 70%;

                p {
                    font-size: .8rem;
                    font-weight: 500;
                    margin: 0;
                }
            }

            .icon-dash {
                display: none;
            }
        }

        content:not(:last-child){
            border-bottom: .5px solid #000;
        }
    }

    @media (max-width: 768px) {
        .faqTable {
            width: auto;
        }
    }
}

@media (max-width: 768px) {
    .faqs {
        padding: 3rem 2rem;
    }
}
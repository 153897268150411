@import "../../../global.scss";

#footer {
    font-size: 14px;
    position: relative;
    margin: 2rem auto;
    padding: 3rem 0 0 0;

    .faq {
        background-color: $secondayColor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;
        border-radius: 1.5rem 1.5rem 0 0;

        .text {
            text-align: left;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            * {
                margin: 0;
            }

            .line {
                border: 2px solid $darkVariaty;
                height: 4rem;
                width: 0;
                margin-right: 2rem;
            }
        }

        .button {
            a {
                padding: .5rem 4rem;
                background-color: $mainColor;
                box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
                border: 1px solid $mainColor;
                border-radius: .5rem;
                font-weight: 700;
                font-size: 1.2rem;
                text-decoration: none;
                color: #000;
            }
        }
    }

    @media (max-width: 480px) {
        .faq {
            flex-direction: column;
            justify-content: end;

            .text {
                margin: 1rem 0;

                h3 {
                    margin-bottom: .5rem;
                }

                .line {
                    height: 7rem;
                }
            }
        }
    }

    @media (max-width: 760px) {
        .faq {
            flex-direction: column;
            justify-content: end;

            .text {
                margin: 1rem 0;

                h3 {
                    margin-bottom: .5rem;
                }

                .line {
                    height: 5rem;
                }
            }
        }
    }

    h3 {
        font-size: 2rem;
        font-weight: 700;
    }

    .line {
        border: 2px solid white;
        width: 90%;
        margin: 1.5rem 0;
    }

    .footer-newsletter {
        padding: 50px 0;
        background: #f3f5fa;
        text-align: center;
        font-size: 15px;
        color: #444444;

        h4 {
            font-size: 24px;
            margin: 0 0 20px 0;
            padding: 0;
            line-height: 1;
            font-weight: 600;
            color: #2A2E36;
        }
    }

    .footer-top {
        background: #fff;

        .container {

            background-color: $darkVariaty;
            border-radius: 0 0 1.5rem 1.5rem;
            color: #FFF;
            padding: 5rem;

            .footer-contact {
                margin-bottom: 30px;
                text-align: left;
                padding: 0 1rem;
    
                .fLogo{
    
                    margin: 0 0 2rem 0;
                }
    
                p {
                    font-size: 1rem;
                    line-height: 24px;
                    margin-bottom: 0;
                    font-family: "Jost", sans-serif;
                }
            }
    
            h4 {
                font-size: 16px;
                font-weight: bold;
                position: relative;
                padding-bottom: 12px;
            }
    
            .footer-links {
                margin-bottom: 30px;
                text-align: left;
                padding: 0 1rem;
    
                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
    
                    i {
                        padding-right: 2px;
                        font-size: 18px;
                        line-height: 1;
                    }
    
                    li {
                        padding: 10px 0;
                        display: flex;
                        align-items: center;
    
                        &:first-child {
                            padding-top: 0;
                        }
                    }
    
                    a {
                        color: #FFF;
                        text-decoration: none;
                        transition: 0.3s;
                        display: inline-block;
                        line-height: 1;
                        font-size: 1.4rem;
    
                        &:hover {
                            text-decoration: none;
                            color: #2A2E36;
                        }
                    }
                }

                form {
                    
                    input {
                        display: block;
                        width: 90%;
                        border: 1px solid transparent;
                        border-radius: .5rem;
                        margin: 0 0 1.5rem 0;
                        padding: .5rem 1rem;
                        font-size: 1rem;
                        outline: none;
                        background-color: transparent;
                    }

                    input[type=email] {
                        border: 1px solid white;
                        color: #fff;

                        &::placeholder {
                            color: #FFF;
                        }
                    }

                    input[type=submit] {
                        background-color: $mainColor;
                        font-weight: 600;
                    }
                }
            }
        }

        @media (max-width: 768px) {
            .container {
                padding: 3rem;
            }
        }

        .sLinks {
            background-color: transparent;
            padding: 0;
        }

        .social-links {
            display: flex;
            justify-content: end;
    
            a {
                font-size: 1.5rem;
                display: inline-block;
                line-height: 1;
                transition: 0.3s;
                margin: 1.5rem 0 1.5rem 1rem;
                color: $mainColor;
            }
        }

        @media (max-width: 480px) {
            .social-links {
                padding: 0;
                justify-content: start;
            }
        }
    }

    .footer-bottom {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 0;
        padding-right: 0;
        align-items: center;
    }
    .logo {
        float: left;

        img {
            with: 60px;
            height: 60px;
        }
    }
    .copyright {
        float: right;
        color: #FFF;

        a {
            text-decoration: underline $mainColor;
            color: #000;
            font-size: .9rem;
            font-weight: 700;
            margin-left: 1rem;
        }
    }

    .credits {
        float: right;
        font-size: 13px;

        a {
            transition: 0.3s;
        }
    }

    @media (max-width: 768px) {
        .copyright, .credits {
            text-align: center;
            float: none;
        }

        .credits {
            padding-top: 4px;
        }
        
    }
}
@import "../../global.scss";

.Quote {
    text-align: left;
    padding: 2rem 1rem;

    .text {
        text-align: center;
        padding: 1rem 0;
        position: relative;

        .line {
            width: 5rem;
            border: 1px solid $mainColor;
            position: absolute;
            top: 50%;
            left: 39%;
        }

        h1 {
            font-weight: 800;
        }

        p {
            font-size: .8rem;
            font-weight: 600;
        }
    }

    span {
        color: red;
    }

    .second {
        .progress-bar {
            background-color: $mainColor;
            width: 10rem;
        }

        .row {
            margin: 2rem 1.5rem;
            justify-content: center;

            .formColl {
                padding: .5rem 1.5rem;

                h6 {
                    font-weight: 700;
                }

                .form-group {
                    margin: 1rem 0;

                    .form-label {
                        font-weight: 600;
                    }

                    input[type="date"]::-webkit-calendar-picker-indicator {
                      color: $mainColor;
                    }
                }
            }
        }

        .progressrow {
            margin: 1rem 0;
        }

        @media (max-width: 760px) {

        }

        .buttons {
            display: flex;
            justify-content: space-between;
            margin: 0 2rem;

            .btn-next {
                background-color: $mainColor;
                border: 1px solid $mainColor;
                padding: .5rem 3rem;
                color: #000;
                font-weight: 700;
            }

            .btn-back {
                color: $mainColor;
                background-color: transparent;
                border: none;
                font-weight: 700;
            }
        }
    }
}

@media (max-width: 480px) {
    .Quote {
        height: 60rem;
    }
}

@media (max-width: 760px) {
    .Quote {
        height: 75rem;
    }
}
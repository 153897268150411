@import "../../../global.scss";

.Office {
    margin: 2rem auto;
    padding: 2rem 0;

    .Officeintro {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;

        .text {

            h1 {
                color: $darkVariaty;
                font-weight: 700;
            }

            h5 {
                font-weight: 600;
            }

            div {
                margin: 2rem 0;
            }

            .button {
                margin: 2rem 0;

                button {
                    padding: .5rem 1.5rem;
                    background-color: $mainColor;
                    box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
                    border: 1px solid $mainColor;
                    border-radius: .3rem;
                    font-weight: 700;
                }
            }
        }
    }

    .value {
        // background-color: $secondayColor;
        border-radius: .5rem;
        padding: 1.5rem 6rem;
        margin: 2rem auto;
        // box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);

        h2 {
            font-weight: 700;
            margin-bottom: 1.5rem;
        }

        p {
            font-weight: 600;
        }
    }
}
@import "../../../global.scss";

.intro {
    position: relative;
    height: calc(100vh - 80px);
    background-image: url('/public/assets/img/home/breadcrum.png');
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .introText {
        h2 {
            font-family: Gilroy;
            font-size: 2.4rem;
            font-weight: 800;
            color: $darkVariaty;
        }

        div {
            width: 70%;
            margin: auto;

            p {
                font-size: 1.3rem;
                font-weight: 600;
                line-height: 120%;
            }
        }

        .buttons {
            margin : 3rem auto;

            .btn {
                padding: .5rem 1.5rem;
                font-weight: 600;
                margin: 0 1rem;
                color: black;
                box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
            }

            .getQuote {
                background-color: $mainColor;
                border: 1px solid $mainColor;
            }

            .trackPackage {
                background-color: transparent;
                border: 1px solid $mainColor;
                color: $mainColor;
            }
        }

        @media (max-width: 991px) {

            .buttons {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .btn {
                    margin: .5rem 0;
                    display: block;
                    width: fit-content;
                }
            }
        }
    }

    @media (max-width: 991px) {
        .introText {
            h2 {
                line-height: 100%;
            }
        }
    }

    @media (max-width: 480px) {
        .introText {
            div {
                width: 80%;
            }
        }
    }

    .imageRow {
        display: flex;
        margin: 2rem auto;

        .imgBox {
            margin: 0 1rem;
        }
    }

    @media (max-width: 480px) {
        .imgBox:not(:first-child) {
            display: none;
        }
    }

    @media (max-width: 760px) {
        .imgBox:not(:nth-child(2n)) {
            display: none;
        }
    }
}